import React, { useEffect, useRef, useState } from "react";
import useData from "../../../redux/customHook/useData";
import EyePrescriptionDownload from "./eyePrescriptionDownload";
import { formatDateToDDMMYY, formatDateToDDMMYYEPRESCRPITON } from "../../../specialMethod/getBetweenDate";
import { toggleState } from "../../../specialMethod/toggleState";
import Spinner from "../../loading/spinner";
import dentist from "../../../../assets/dentist.png"
import medicalSymbol from "../../../../assets/MedicalSymbol.jpg"
import {  useHandleWhatsappPrescription } from "../Eprescription/function/ePrescriptionFunction"
import { uploadPrescription } from "../Eprescription/function/ePrescriptionFunction"
import { useReactToPrint } from "react-to-print";

const EpresciptionDownload = ({
  doctorDetails,
  patientData,
  reset,
  prescriptionPhotoURLs,
  prescriptionResult,
  eyeData,
  selectedDate,
  xrayImages
}) => {
  const { data } = useData();
  const [state, setState] = useState({
    loadingSpinner: false
  });
  const [prescriptionImage, setPrescriptionImage] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [prescriptionUrl, setPrescriptionUrl] = useState(null);
  const captureRef = useRef(null);
  const excludeRef = useRef();
  const getAllDegree = doctorDetails.degree;

  // Map clinic locations to their IDs
  const clinicLocationToIdMap = data?.clinicDtos?.reduce((map, clinic) => {
    map[clinic.location] = clinic.id;
    return map;
  }, {});

  // Set prescription image based on clinic ID
  useEffect(() => {
    if (data?.clinicDtos && patientData.clinicLocation) {
      const activeClinics = data.clinicDtos.filter(
        (clinic) => clinic.clinicStatus === "Active"
      );
      const clinicData = activeClinics.find(
        (clinic) => clinic.location === patientData.clinicLocation
      );
      if (clinicData) {
        setClinicDetails(clinicData)
        setPrescriptionImage(prescriptionPhotoURLs[clinicData.id]);
      }
    }
  }, [clinicLocationToIdMap, patientData.clinicLocation, prescriptionPhotoURLs]);

  useEffect(() => {
    uploadPrescription(captureRef, excludeRef, patientData, setPrescriptionUrl, toggleState,setState, doctorDetails ,dentist , medicalSymbol ,"dentistImg", // Pass ID of dentist image element
      "medicalPriscriptionImg");
  }, [])

  const handlePrint = useReactToPrint({
    content: () => captureRef.current, // Make sure this is the only reference
  });
  
  const specialization = doctorDetails.specialization[0].toUpperCase();

  const {handleWhatsappPrescription }= useHandleWhatsappPrescription(prescriptionUrl, patientData, doctorDetails, clinicDetails, toggleState,setState, reset)

  
  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex"
    >
      <div className="relative p-4 w-full max-w-6xl h-full md:h-[99%]">
        <div className="relative bg-white rounded-lg shadow w-full h-[99%] my-2 max-h-screen overflow-y-auto">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
            onClick={reset}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="#c6c7c7"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close popup</span>
          </button>

          <div className="" ref={captureRef}>
            {prescriptionImage ? (
              <img
                className="w-full h-auto"
                src={`data:image/jpeg;base64,${prescriptionImage}`}
                alt="Prescription"
              />
            ) : (
              <div className="p-5">
                <h5 className="text-lg mb-0.5 font-medium">
                  {doctorDetails.firstName} {doctorDetails.lastName}
                </h5>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {doctorDetails.specialization}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  {getAllDegree}
                </p>
                <p className="mb-1 text-sm font-normal text-gray-800">
                  Location: {patientData.clinicLocation}
                </p>
                <p className="mb-4 text-sm font-normal text-gray-800">
                  Mobile No: {patientData.contact}
                </p>
              </div>
            )}

            <form className="w-full">
              <div className="flex items-center justify-around  pl-4 md:p-2 border-t-2 border-gray-200 rounded-b dark:border-black">
                <p className="text-black  text-sm px-1 py-1 text-center ">
                  Patient Name: &nbsp;{patientData.name}
                </p>
                <p className="text-black text-sm px-1 py-1 text-center ">
                  Age: &nbsp;{patientData.age}
                </p>
                <p className="text-black text-sm px-2 py-1 text-center ">
                  Sex: &nbsp;{patientData.gender}
                </p>
                <p className="text-black text-sm px-2 py-1 text-center ">
                  Date: &nbsp;{patientData.appointmentDate}
                </p>
              </div>
              <div class="flex flex-wrap ">
                <div
                  className={`${specialization === "EYE SPECIALIST" || specialization === "DENTIST"
                    ? "w-2/3"
                    : "w-full"
                    } `}
                >
                  <section className="text-gray-600 body-font">
                    <div className="container px-5 mx-auto">
                      <div className="flex flex-wrap -m-4">
                        <div className="flex flex-col flex-wrap p-4 w-1/2">
                          <div className="left-3 ">
                            {prescriptionResult.complaint?.length > 0 && (
                              <div className="mb-2">
                                <div className="text-xl text-gray-900">Complaints</div>
                                <div className=" w-2/3">
                                  {prescriptionResult.complaint.map((complaint, index) => (
                                    <div key={index} className="px-2  rounded-md">
                                      {complaint}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {prescriptionResult.diagnosis?.length > 0 && (
                              <div className="mb-2">
                                <div className="text-xl text-gray-900">Diagnosis</div>
                                <div className="">
                                  {prescriptionResult.diagnosis.map((diagnosis, index) => (
                                    <div key={index} className="px-2 rounded-md">
                                      {diagnosis}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}



                          </div>
                        </div>
                        <div class="flex flex-col w-1/2">
                          <nav class="flex flex-wrap list-none pt-5 ml-5">
                            {prescriptionResult.height && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  Height:- {prescriptionResult.height} cm
                                </p>
                              </li>
                            )}
                            {prescriptionResult.weight && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  Weight:- {prescriptionResult.weight} kg
                                </p>
                              </li>
                            )}
                            {prescriptionResult.highbp && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  Bp:- {prescriptionResult.highbp}/{" "}
                                  {prescriptionResult.lowbp}
                                </p>
                              </li>
                            )}
                            {prescriptionResult.pulse && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  {specialization === "PEDIATRICIAN" ? "Head Circum." : "Pulse"}  :- {prescriptionResult.pulse}
                                </p>
                              </li>
                            )}
                            {prescriptionResult.spo && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  SPO2:- {prescriptionResult.spo}
                                </p>
                              </li>
                            )}
                            {prescriptionResult.temp && (
                              <li class="mb-1 w-1/2">
                                <p class="text-gray-600 hover:text-gray-800 ml-4">
                                  Temp:- {prescriptionResult.temp}
                                </p>
                              </li>
                            )}
                          </nav>
                        </div>

                      </div>
                      {prescriptionResult.examination?.length > 0 && (
                        <div className="mb-2">
                          <div className="text-xl text-gray-900">Examination</div>
                          <div className="">
                            {prescriptionResult.examination.map((examine, index) => (
                              <div key={index} className="px-2 rounded-md">
                                {examine}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {prescriptionResult.treatmentInput?.length > 0 && (
                        <div className="mb-2">
                          <div className="text-xl text-gray-900">Treatment</div>
                          <div className="">
                            {prescriptionResult.treatmentInput.map((treatment, index) => (
                              <div key={index} className="px-2 rounded-md">
                                {treatment}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {prescriptionResult.test?.length > 0 && (
                        <div className="mb-2">
                          <div className="text-xl text-gray-900">Test</div>
                          <div className="">
                            {prescriptionResult.test.map((tests, index) => (
                              <div key={index} className="px-2 rounded-md">
                                {tests}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <img
                        src={medicalSymbol}
                        alt="Example medical"
                        id="medicalPriscriptionImg"
                        className="w-16 h-13"
                      />
                      {prescriptionResult.medicines?.length > 0 && (
                        <div> {specialization === "DENTIST" || specialization == "EYE SPECIALIST" ?
                          <>
                            {prescriptionResult.medicines.map((med, i) => (
                              <div key={i} className="">
                                {" "}
                                <span className="">
                                  {med.name}
                                </span>
                                <span className="text-[12px]">
                                  {med.composition}
                                </span>
                                <span className="">
                                  {med.dose}
                                </span>
                                {specialization === "PEDIATRICIAN" &&
                                  <span className="">
                                    {med?.routine}
                                  </span>
                                }
                                <span className="">
                                  {med.when}
                                </span>
                                <span className="">
                                  {med.frequency}
                                </span>
                                <span className="">
                                  {med.duration}
                                </span>
                              </div>
                            ))}</> :
                          <div class="relative overflow-x-auto sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-black">
                              <thead className="text-xs text-black uppercase bg-gray-900 ">
                                <tr>
                                  {[
                                    "",
                                    "Name",
                                    "Dose",
                                    ...(specialization === "PEDIATRICIAN" ? ["Route"] : []),
                                    "When",
                                    "Frequency",
                                    "Duration",
                                  ].map((header, index) => (
                                    <th
                                      key={index}
                                      className="px-2 py-1 bg-gray-200 "
                                    >
                                      {header}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {prescriptionResult.medicines.map((med, index) => (
                                  <tr
                                    key={index}
                                    className="border-b bg-gray-100 border-gray-900"
                                  >
                                    <th className="px- font-medium text-black whitespace-nowrap ">
                                      {index + 1}
                                    </th>
                                    <td className="px-2 ">
                                      {med.name}
                                      <div className="text-[12px]">
                                        {med.composition}
                                      </div>
                                    </td>

                                    <td className="px-2 ">
                                      {med.dose}
                                    </td>
                                    {specialization === "PEDIATRICIAN"
                                      &&
                                      <td className="px-2 ">
                                        {med.routine}
                                      </td>}
                                    <td className="px-2 ">
                                      {med.when}
                                    </td>
                                    <td className="px-2 ">
                                      {med.frequency}
                                    </td>
                                    <td className="px-2 ">
                                      {med.duration}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        }
                        </div>)}
                        <div className="flex justify-between mt-4">
  {/* Left Side: Procedure Summary & Advice */}
  <div className="w-1/2">
    {prescriptionResult.procedureInput?.length > 0 && (
      <div className="mb-2">
        <div className="text-xl text-gray-900">Procedure Summary</div>
        <div>
          {prescriptionResult.procedureInput.map((procedure, index) => (
            <div key={index} className="px-2 rounded-md">
              {procedure}
            </div>
          ))}
        </div>
      </div>
    )}

    {prescriptionResult.advice?.length > 0 && (
      <div className="mb-2">
        <div className="text-xl text-gray-900">Advice</div>
        <div>
          {prescriptionResult.advice.map((advices, index) => (
            <div key={index} className="px-2 rounded-md">
              {advices}
            </div>
          ))}
        </div>
      </div>
    )}
  </div>

  {/* Right Side: Next Appointment Date */}
  {formatDateToDDMMYY(selectedDate) !== formatDateToDDMMYY(new Date()) && (
    <div className="w-1/2 flex justify-end items-end">
      <div className="mb-2 print:block hidden">
        <div className="text-lg text-gray-600">
          Next Appointment Date: <span className="text-gray-900">{formatDateToDDMMYYEPRESCRPITON(selectedDate)}</span>
        </div>
      </div>
    </div>
  )}
</div>

                    </div>
                  </section>
                </div>
                {specialization === "EYE SPECIALIST" && (
                  <EyePrescriptionDownload eyeData={eyeData} />
                )}
                {specialization === "DENTIST" && (
                  <div className="w-1/3 h-1/3 my-auto">
                    <img src={dentist} alt="dentist" id="dentistImg" />

                    <div>
                      {xrayImages?.length > 0 && (
                        <div className="flex flex-wrap">
                          {/* X-ray Report Heading */}
                          <div className="w-full text-xl m-2">Xray Report</div>

                          {/* X-ray Images */}
                          {xrayImages.map((image, index) => (
                            <div key={index} className="w-1/2">
                              <img
                                src={image}
                                className="h-28 w-28 m-2 object-cover rounded"
                                alt={`X-ray ${index}`}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                  </div>)}
              </div>
              <div
                ref={excludeRef}
                className="no-print flex items-center p-1 md:p-5 mb-2 border-gray-200 rounded-b dark:border-gray-600"
              >
                <div className="flex">
                  <button
                    data-modal-hide="default-modal"
                    type="button"
                    onClick={handlePrint}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Print
                  </button>
                  <div className="relative mx-2">
                    <button
                      type="button"
                      onClick={handleWhatsappPrescription}
                      className="w-full bg-gray-100 border-green-300 hover:border-green-800 hover:cursor-pointer flex items-center justify-center gap-2 rounded border  focus:border-indigo-500 focus:ring-2 text-[14px] outline-none text-green-500 py-1 px-2 leading-8 transition-colors duration-200 ease-in-out"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/4423/4423697.png"
                        alt="WhatsApp"
                        className="w-5 h-5"
                      />
                      WhatsApp
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
    </div>
  );
};

export default EpresciptionDownload;