export const specializationOption = [
    { value: 'Cardiologist', label: 'Cardiologist' },
    { value: 'Neurologist', label: 'Neurologist' },
    { value: 'Gynaecologist', label: 'Gynaecologist' },
    { value: 'Dentist', label: 'Dentist' },
    { value: 'Eye Specialist', label: 'Eye Specialist' },
    { value: 'ENT', label: 'ENT' },
    { value: 'Pulmonologist', label: 'Pulmonologist' },
    { value: 'Orthopaedic', label: 'Orthopaedic' },
    { value: 'Gastroenterologist', label: 'Gastroenterologist' },
    { value: 'Ophthalmologist', label: 'Ophthalmologist' },
    { value: 'Nephrologist', label: 'Nephrologist' },
    { value: 'Urologist', label: 'Urologist' },
    { value: 'General Physician', label: 'General Physician' },
    { value: 'Pediatrician' , label : 'Pediatrician'}
  ];