import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useData from "../../redux/customHook/useData";
import Spinner from "../loading/spinner";
import { toggleState } from "../../specialMethod/toggleState";
import {
  handlePayment,
  handleOrderFunction,
  handleVerifyPayment,
  handleSavedEnd,
  handleHistory,
  handleGetEndDate,
  loadScript,
} from "../../razorrpay/razorpay";
import InvoiceSend from "./invoiceSend";



//hello how r u github?
const SubscriptionModal = ({ value, invoiceDetails, email }) => {
  const [state, setState] = useState({
    loadingSpinner: true,
    selectedAmount: null,
    clinicLength: null,
    invoiceSend: false
  });
  const navigate = useNavigate();
  const { data } = useData();

  useEffect(() => {
    if (data?.clinicDtos) {
      toggleState("loadingSpinner", false, setState);
      const activeClinics = data.clinicDtos.filter(clinic => clinic.clinicStatus === "Active");
      toggleState("clinicLength", activeClinics.length, setState);
    }
  }, [data]);

  const handleVerify = useCallback(
    async (razorpayresponse, order_id, amount, duration) => {
      try {
        const verify = await handleVerifyPayment(razorpayresponse);
        const token = sessionStorage.getItem("token");
        const date = await handleGetEndDate(token, data.id);
        if (verify) {
          const saved = await handleSavedEnd(
            razorpayresponse,
            duration,
            order_id,
            amount,
            date,
            data
          );
          if (saved) {
            const history = await handleHistory(
              order_id,
              razorpayresponse,
              duration,
              amount,
              date,
              data
            );
            if (history) {
              toggleState("invoiceSend", true, setState);
            }
          }
        }
      } catch (error) {
        console.error("Error in payment verification:", error);
      }
    },
    [state.selectedAmount, data, navigate]
  );
  const handleRazorpayScreen = useCallback(
    async (amount, order_id, duration) => {
      try {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
          console.error("Failed to load Razorpay script");
          return;
        }

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: amount,
          currency: "INR",
          name: "Doc-aid",
          description: "Payment",
          order_id: order_id,
          image: "https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png",
          handler: (response) => handleVerify(response, order_id, amount, duration),
          prefill: {
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            contact: data.contact,
          },
          theme: {
            color: "#F37254",
          },
        };
        const paymentsObject = new window.Razorpay(options);
        paymentsObject.open();
      } catch (error) {
        console.error("Error in Razorpay screen:", error);
      }
    },
    [data, handleVerify]
  );
  const handleChoosePlan = useCallback(
    async (month) => {
      try {
        const token = sessionStorage.getItem("token");
        const amount = await handlePayment(
          token,
          state.selectedAmount,
          state.clinicLength
        );
        if (amount) {
          const order_id = await handleOrderFunction(amount);
          await handleRazorpayScreen(amount, order_id, state.selectedAmount);
        }
      } catch (error) {
        console.error("Error in choosing plan:", error);
      }
    },
    [state.selectedAmount, state.clinicLength, data, handleRazorpayScreen]
  );



  const handlePlanSelection = useCallback(
    (amount) => {
      toggleState("selectedAmount", amount, setState);
    },
    [setState]
  );

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full flex items-center justify-center"
    >
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
          </div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close"
              onClick={value}
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-4xl leading-6 mt-10 font-medium text-gray-900">
                  Pick Your Perfect Plan
                </h3>
              </div>
            </div>
 

            <section className="text-gray-600 body-font">

              <div className="container px-5 pt-16 mx-auto">
                <div className="flex flex-wrap -m-4">
                  {[
                    { label: "Monthly", amount: 1, price: "1500 + 500/ Additional Clinics" },
                    { label: "Quarterly", amount: 3, price: "4000 + 1500/ Additional Clinics" },
                    { label: "Half Yearly", amount: 6, price: "8000 + 3000/ Additional Clinics" },
                    { label: "Yearly", amount: 12, price: "15000 + 6000/ Additional Clinics" },
                  ].map((plan) => (
                    <div
                      key={plan.amount}
                      onClick={() => handlePlanSelection(plan.amount)}
                      className={`lg:w-[295px] cursor-pointer md:w-1/2 p-4 mr-3 mb-2 w-full border-2 rounded-lg border-gray-300 ${state.selectedAmount === plan.amount ? "border-red-400" : ""
                        }`}
                    >
                      <div className="">
                        <h3 className="text-gray-500 text-xl font-bold mb-6">
                          {plan.label}
                        </h3>
                        <p className="mt-1 text-lg">
                          ₹{" "}
                          <span className="text-2xl text-red-400 font-bold">
                            {plan.price}
                          </span>
                        </p>
                        {plan.amount !== 12 && ( // Conditionally render GST text
        <p className="text-red-400 mt-2 text-lg">+18% gst</p>
      )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="flex items-center justify-center mt-4">
                <button
                  type="submit"
                  class="bg-red-400 text-white font-semibold py-4 px-12 rounded-md hover:bg-red-500 transition duration-300"
                  onClick={() => handleChoosePlan(state.selectedAmount)}
                >
                  Proceed to Pay
                </button>
              </div>

            </section>
          </div>
        </div>
      </div>
      {state.loadingSpinner && <Spinner />}
      {state.invoiceSend && <InvoiceSend reset={() => toggleState("invoiceSend", false, setState)}
        email={email} />}
    </div>
  );
};

export default SubscriptionModal;
