import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import apiServices from "../../../../../Service/apiService";

export const fetchImageAsBase64 = async (url) => {
    try {
      if (!url) throw new Error("URL is undefined or empty");
  
      // Prevent browser caching issues by adding a timestamp
      const cacheBypassUrl = `${url}?date=${Date.now()}`;
      console.log("Fetching Image from:", cacheBypassUrl);
  
      const response = await fetch(cacheBypassUrl);
      if (!response.ok) throw new Error(`Failed to fetch image: ${response.statusText}`);
  
      const blob = await response.blob();
  
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading image:", error);
      return null;
    }
  };


  
  export const uploadPrescription = async (captureRef, excludeRef, patientData, setPrescriptionUrl, toggleState,setState, doctorDetails ,dentist , medicalSymbol, dentistImgId, medicalPriscriptionImgId ) => {
    try {
      // Hide the excluded element before capturing
      if (excludeRef.current) excludeRef.current.style.display = "none";
  
      // Fetch and embed external images as Base64
      const dentistBase64 = await fetchImageAsBase64(dentist);
      if (dentistBase64) {
        const dentistImg = document.querySelector(dentistImgId);
        if (dentistImg) {
          dentistImg.src = dentistBase64;
          console.log("Updated Dentist Image Src:", dentistImg.src);
        }
      }
  
      const medicalPriscriptionBase64 = await fetchImageAsBase64(medicalSymbol);
      if (medicalPriscriptionBase64) {
        const medicalPriscriptionImg = document.querySelector(medicalPriscriptionImgId);
        if (medicalPriscriptionImg) {
          medicalPriscriptionImg.src = medicalPriscriptionBase64;
          console.log("Updated Medical Prescription Image Src:", medicalPriscriptionImg.src);
        }
      }
  
      const input = captureRef.current;
      const pdf = new jsPDF("p", "mm", "a4");
  
      // Ensure all images are loaded before capturing
      const images = input.querySelectorAll("img");
      const imageLoadPromises = Array.from(images).map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) resolve();
            else img.onload = img.onerror = resolve;
          })
      );
      await Promise.all(imageLoadPromises);
  
      // Generate canvas and capture content
      const canvas = await html2canvas(input, {
        scale: 0.8, // Higher scale for better quality
        useCORS: true,
        allowTaint: false,
      });
  
      // Convert canvas to image data
      const imgData = canvas.toDataURL("image/jpeg", 1);
  
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
  
      // Add the first page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
  
      // Add additional pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
  
      // Restore the excluded element's display property
      if (excludeRef.current) excludeRef.current.style.display = "";
  
      // Convert the PDF to a blob
      const pdfBlob = pdf.output("blob");
      console.log("PDF size in bytes:", pdfBlob.size);
      const d = new Date();
      let time = d.getTime();
  
      // Prepare FormData for API submission
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("file", pdfBlob, `${patientData.name}${time}_prescription.pdf`);
  
      toggleState("loadingSpinner", true, setState);
  
      // Submit the PDF to the API
      console.log("v")
      const response = await apiServices.post(
        "authentication",
        "/s3fileUpload/uploadPrescriptionFile",
        null,
        null,
        formDataToSubmit
      );
      console.log("n")
      if (response) {
        console.log("m",response)
        setPrescriptionUrl(response);
        handleStorePrescription(response , patientData, doctorDetails, toggleState,setState);
      } else {
        console.error("Failed to upload PDF:", response?.status || "Unknown error");
      }
    } catch (error) {
      console.error("Error submitting PDF:", error.message);
    } finally {
      toggleState("loadingSpinner", false, setState);
    }
  };


  
  export const handleStorePrescription = async (prescription, patientData, doctorDetails, toggleState,setState) => {
    console.log("s",prescription)
    try {
      const response = await apiServices.post(
        "authentication",
        "/PrescriptionHistoryData/create",
        null,
        null,
        {
          url: prescription,
          appointmentDate: patientData.appointmentDate,
          drName: doctorDetails.firstName + " " + doctorDetails.lastName,
          doctorSpecialization: doctorDetails.specialization[0],
          patientId: patientData.patientId
        }
      );
      if (response.statusCode === 201) {
        toggleState("loadingSpinner", false, setState);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      toggleState("loadingSpinner", false, setState);
    }
  }


export const useHandleWhatsappPrescription = (prescriptionUrl, patientData, doctorDetails, clinicDetails, toggleState,setState, reset) => {
  const handleWhatsappPrescription = async () => {
  try {
    const response = await apiServices.post(
      "authentication",
      "/whatsapp/prescription_confirmation",
      null,
      {
        s3url: prescriptionUrl
      },
      {
        "patientName": patientData.name,
        "patientContact": patientData.contact,
        "drName": doctorDetails.firstName + " " + doctorDetails.lastName,
        "compounderName": clinicDetails?.incharge,
        "clinicContact": clinicDetails?.clinicContact
      }
    );
    if (response) {
      console.log("all data")
      toggleState("loadingSpinner", false, setState);
      reset()
      return response.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    toggleState("loadingSpinner", false, setState);
  }
}

  return { handleWhatsappPrescription };
};