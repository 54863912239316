import apiServices from "../../../../Service/apiService";
import { handleGetEndDate, handleSavedEnd } from "../../../razorrpay/razorpay";
import { formatDateToDDMMYY } from "../../../specialMethod/getBetweenDate";

export const handleReferral = async (formData, doctorId, duration) => {
    const token = sessionStorage.getItem("token");
    // check(get) this new doctor in temporary data
    let temporary = await  getTemporaryData(formData.contact);
    console.log("temporary", temporary)
    //if yes 
    //delete from temporary data table
    // update current subscription
    if (temporary !== 0) {
        await deleteTemporaryData(formData.contact)
        const date =await  handleGetEndDate(token, doctorId)
        console.log("date", date)
        if (date) {
            await handleSavedEnd({ razorpay_payment_id: "referred" }, temporary, "order_id", 100, date,{ id: doctorId })
        }
    }
    // referral doctor in temporary data
    let id = await handleDbCheck(formData.referral)
    if (id !==0) {
        // if in our db
        // update current subscription
        const date =await handleGetEndDate(token, id)
        if (date) {
            await handleSavedEnd({ razorpay_payment_id: "referred" }, duration, "order_id", 100, date, { id: id })
        }
    } else {
        //check if referral doctor in temporary data
        let temporary =await getTemporaryData(formData.referral);
        console.log("temporary",temporary)
        if (temporary !== 0) {  //if yes 
            // update duration temporary table
            await updateTemporaryData(formData, duration + temporary);
        } else {
            //create in temporary data
            await createTemporaryData(formData, duration)
        }

    }
    // history api
    try {
        const response = await apiServices.post(
            "authentication",
            "/ReferralHistory/create",
            null,
            null,
            {
                "referredToName": formData.firstName + " " + formData.lastName,
                "referredToEmail": formData.email,
                "referredToContact": formData.contact,
                "referredByContact": formData.referral,
                "date": formatDateToDDMMYY(new Date())
            }
        );
        if (response.statusCode === 200) {
            return true
        }
    } catch (error) {
        if (error.response.data.statusCode === 404) {
            return false
        }
    }
};

export const getTemporaryData = async (number) => {
    try {
        const response = await apiServices.get(
            "authentication",
            `/TemporaryReferralData/get/${number}`,
            null,
            null,
            null
        );
        if (response.statusCode === 200) {
            console.log("response?.data.duration",response?.data.duration)
            return response?.data.duration
        }else{
            return 0;
        }
    } catch (error) {
        if (error.response.data.statusCode === 404) {
            return 0
        }
    }
}
export const deleteTemporaryData = async (number) => {
    try {
        const response = await apiServices.delete(
            "authentication",
            `/TemporaryReferralData/delete/${number}`,
            null,
            null,
            null
        );
        if (response.statusCode === 200) {
            console.log("deleted")
        }
    } catch (error) {
        if (error.response.data.statusCode === 404) {
            console.log("error")
        }
    }
}

export const handleDbCheck = async (number) => {
    try {
        const response = await apiServices.get(
            "authentication",
            "/home/doctorByMobileNo",
            null,
            { mobileNumber: number },
            null
        );

        if (response?.statusCode === 200) {
            return response.data.id;
        } else {
            return 0;
        }
    } catch (error) {
        return 0;
    }
}

export const updateTemporaryData = async (formData, duration) => {
    const response = await apiServices.put(
        "authentication",
        "/TemporaryReferralData/update",
        null,
        null,
        {
            "referredByContact": formData.referral,
            "duration": duration,
            "referredToEmail": formData.email
        }
    );
}
export const createTemporaryData = async (formData, duration) => {
    const response = await apiServices.post(
        "authentication",
        "/TemporaryReferralData/create",
        null,
        null,
        {
            "referredByContact": formData.referral,
            "duration": duration,
            "referredToEmail": formData.email
        }
    );
}