import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import apiServices from "../../../Service/apiService";
import  debounce  from "lodash.debounce";

const DoctorDetailsForm = ({ onNext, formData, setFormData }) => {
  const defaultImageSrc = "path_to_default_image_or_logo.png"; //
  const [salectImage, setSalectImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setSalectImage(base64String); // Display the selected image
        setFormData((prevData) => ({
          ...prevData,
          imageUpload: base64String, // Update formData with the base64 string
        }));
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  console.log(salectImage);
  const validateForms = useFormik({
    initialValues: {
      firstName: formData.firstName || "Dr.",
      lastName: formData.lastName || "",
      contact: formData.contact || "",
      email: formData.email || "",
      password: formData.password || "",
      confirm_Password: formData.confirm_Password || "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("enter your firstName"),
      lastName: Yup.string().required("enter your lastName"),
      contact: Yup.string()
        .required("enter phone number")
        .matches(/^\d{10}$/, "Number must be 10 digits"),
      email: Yup.string().email().required("enter your email"),
      password: Yup.string()
        .min(7, "Password must be atleast 7 characters long")
        .max(20, "Password cannot more than 20 charcter long ")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("please enter the password")
        .test("Password cannot contain your name ", function (value) {
          const nameFromEmail = this.parent.email.split("@")[0].toLowerCase();
          return !value.toLowerCase().includes(nameFromEmail);
        }),
      confirm_Password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match") // Reference 'password' field
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      handleIsValid(values);
    },
  });

  const handleIsValid = async (values)=>{
    try {
      const response = await apiServices.get(
        "authentication",
        "/home/checkMobileNoExist",
        null,
        {  mobileNumber: validateForms.values.contact },
        null
      );

      if (response?.data === false) {
        onNext(values)
      } else {
        validateForms.setFieldError("contact", "This contact is already registered.");
        toast.error("This contact is already registered.");
      }
    } catch (error) {
      toast.error("Error checking contact no. Please try again.");
    }
  }

  return (
    <>
      <div className="p-2">
        {/* register form for doctor personal details */}
        <div className="h-full bg-white dark:bg-white">
          <div className="mx-auto">
            <div className="flex justify-center md:px-6 py-6">
              <div className="w-full lg:w-7/12 bg-white dark:bg-white p-5 rounded-lg lg:rounded-l-none">
                <form
                  className="px-8 pt-6 pb-8 mb-4 bg-white  rounded"
                  style={{ backgroundColor: "rgb(202,255,255)" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    validateForms.handleSubmit();
                  }}
                >
                  <div className="flex items-center pb-4 space-x-4">
                    {salectImage && (
                      <div className="flex-shrink-0">
                        <img
                          className="h-28 w-28 object-cover rounded-full"
                          src={salectImage || defaultImageSrc}
                          alt="Current profile"
                        />
                      </div>
                    )}
                    <label className="block relative overflow-hidden">
                      <span className="sr-only">Choose profile photo</span>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                      <span className="file-button bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-700">
                        Select Image
                      </span>
                    </label>
                  </div>

                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <input
                        type="text"
                        id="first_name"
                        name="firstName"
                        value={validateForms.values.firstName}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="First Name"
                      />
                      {validateForms.touched.firstName &&
                      validateForms.errors.firstName ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.firstName}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="last_name"
                        name="lastName"
                        value={validateForms.values.lastName}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Last Name"
                      />
                      {validateForms.touched.lastName &&
                      validateForms.errors.lastName ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={validateForms.values.email}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Email"
                      />
                      {validateForms.touched.email ||
                      validateForms.errors.email ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="number"
                        id="phone"
                        name="contact"
                        value={validateForms.values.contact}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Mobile Number"
                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      />
                      {validateForms.touched.contact ||
                      validateForms.errors.contact ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.contact}
                        </p>
                      ) : null}
                    </div>

                    <div>
                      <input
                        type="password"
                        id="visitors"
                        name="password"
                        value={validateForms.values.password}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Password"
                      />
                      {validateForms.touched.password &&
                      validateForms.errors.password ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.password}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="confirm_password"
                        name="confirm_Password"
                        value={validateForms.values.confirm_Password}
                        onChange={validateForms.handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Confirm Password"
                      />
                      {validateForms.touched.confirm_Password &&
                      validateForms.errors.confirm_Password ? (
                        <p style={{ color: "red" }}>
                          {validateForms.errors.confirm_Password}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="mb-6 text-center">
                      <button
                        className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 dark:bg-blue-700 dark:text-white dark:hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                        type="submit"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DoctorDetailsForm;
