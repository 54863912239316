import React from "react";

const SuccessPage = ({ onSubmit, onPrev, formData, imageData }) => {
  // Null check for imageData before using
  const imageSrc = imageData || "path_to_default_image_or_logo.png";

  return (
    <>
      <div className="p-2">
        {/* register form for doctor personal details */}
        <div className="h-full bg-white dark:bg-white">
          <div className="mx-auto">
            <div className="flex justify-center md:px-6 py-6">
              <div className="w-full lg:w-7/12 bg-white dark:bg-white p-5 rounded-lg lg:rounded-l-none">
                <div
                  className="px-8 pt-6 pb-8 mb-4 bg-white dark:bg-gray-600 rounded"
                  style={{ backgroundColor: "rgb(202,255,255)" }}
                >
                  <div className="   flex flex-row mb-2 items-center justify-center">
                    {/* Image */}
                    <div className="mr-4">
                      <img
                        src={imageSrc}
                        alt="Doctor"
                        className="w-32 h-32 object-cover rounded-full"
                      />
                    </div>
                  </div>
                  <p>Doctor Personal Details</p>
                  <div className="flex sm:flex-row flex-col mb-2">
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.firstName}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.lastName}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.email}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="flex sm:flex-row flex-col mb-2">
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.contact}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        type="password"
                        value={formData.password}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.confirm_Password}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <p>Doctor Practice Form Details </p>
                  {formData.doctors.map((value, index) => (
                    <div key={index} className="flex sm:flex-row flex-col mb-2">
                      <div className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value.degree}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value.college}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value.year}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                  ))}

                  <div className="flex sm:flex-row flex-col mb-2">
                    {formData.specialization.map((value, index) => (
                      <div key={index} className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    ))}
                    {formData.achievements.map((value, index) => (
                      <div key={index} className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    ))}

                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.experience}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="flex sm:flex-row flex-col mb-2">
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.licenceNumber}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    {formData.research_journal.map((value, index) => (
                      <div key={index} className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    ))}
                    {formData.citations.map((value, index) => (
                      <div key={index} className="flex flex-col flex-grow mr-4 mb-2">
                        <input
                          value={value}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    ))}
                    <div className="flex flex-col flex-grow mr-4 mb-2">
                      <input
                        value={formData.referral}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <div className="mb-4 mr-2 text-center">
                      <button
                        className="w-full px-4 py-2 font-bold text-gray-500 bg-blue-500 rounded-full  dark:bg-white dark:text-gray-500  focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={onPrev}
                      >
                        Previous
                      </button>
                    </div>

                    <div className="mb-4 text-center">
                      <button
                        className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 dark:bg-blue-700 dark:text-white dark:hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                        type="Submit"
                        onClick={onSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessPage;
